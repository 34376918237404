<template>
  <div class="container" style="height: 82vh">
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="fad fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div v-else class="h-100">
      <div class="row mb-3 h-100">
        <div class="col mx-auto">
          <div class="card border-0 shadow-sm h-100">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5>{{ this.request.plan.title }}</h5>
                  <h5><small>{{ this.request.created_at | formatDate }}</small></h5>
                  <hr v-if="currentQuestionIndex"/>
                </div>
              </div>
              <div class="row" v-if="!currentQuestionIndex && !request.client_response_date">
                <div class="col text-center">
                  <i class="fad fa-info-circle text-primary fa-3x mb-3"></i>
                  <p>
                    Please answer the following {{request.questions.length}} questions. Once you are done, click 'Submit Response' to send to your practitioner.
                  </p>
                </div>
              </div>
              <div class="row" v-if="!currentQuestionIndex && request.client_response_date">
                <div class="col text-center">
                  <i class="fad fa-info-circle text-primary fa-3x mb-3"></i>
                  <p>
                    You responded to this request on {{request.client_response_date | formatDate}}. You can view your responses but are unable to make any further changes.
                  </p>
                </div>
              </div>
              <div v-if="currentQuestionIndex">
                <div class="row">
                  <div class="col">
                    <h5>Question #{{currentQuestionIndex}}</h5>
                    <p>{{currentQuestion.question.question}}</p>
                    <vue-slider
                            class="mb-5 mx-2"
                            :min="0"
                            :max="10"
                            :interval="1"
                            :marks="true"
                            v-model="currentQuestion.client_response"
                            @change="updateQuestion(currentQuestion)"
                            :disabled="request.client_response_date"
                    />
                    <hr />
                  </div>
                </div>
                <div class="row" v-if="currentQuestion.question.additional_text">
                  <div class="col">
                    {{currentQuestion.question.additional_text}}
                    <hr />
                  </div>
                </div>
                <div class="row" v-if="currentQuestion.question.enable_comments">
                  <div class="col">
                    <textarea class="form-control"
                              placeholder="Add a comment (max 500 characters)"
                              v-model="currentQuestion.client_comment"
                              @blur="updateQuestion(currentQuestion)"></textarea>
                    <hr />
                  </div>
                </div>
                <div class="row mb-1 position-absolute w-100" style="bottom: 12px;">
                  <div class="col-auto">
                    <button class="btn btn-outline-primary" @click="prevQuestion">
                      <i class="fad fa-arrow-left"></i>
                      Previous
                    </button>
                  </div>
                  <div class="col text-right">
                    <button class="btn btn-outline-primary float-right mr-2"
                            v-if="currentQuestionIndex !== request.questions.length"
                            @click="nextQuestion"
                    >
                      <i class="fad fa-arrow-right"></i>
                      Next
                    </button>
                    <span v-else>
                      <button class="btn btn-primary"
                              @click="submitResponse"
                              v-if="request.status !== 'responded'"
                      >
                        <i class="fad fa-share mr-1" v-if="!busySending"></i>
                        <i class="fad fa-spinner fa-spin mr-1" v-else></i>
                        Submit Response
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row" v-if="!currentQuestionIndex">
                <div class="col text-center">
                  <button class="btn btn-primary"
                          @click="nextQuestion"
                  >
                    <i v-if="!request.client_response_date"
                       class="fad fa-arrow-right mr-1"></i>
                    <span v-if="!request.client_response_date">Start</span>
                    <span v-else>View Your Response</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
export default {
  props: [],
  data() {
    return {
      busy: true,
      busySending: false,
      request: null,
      started: false,
      currentQuestionIndex: null,
      currentQuestion: null,
    };
  },
  computed: {
    user() {
      return this.$store.user;
    }
  },
  methods: {
    fetchRequest() {
      this.$axios
              .get(process.env.VUE_APP_API_URL + "/client/mymop/requests/" + this.$route.params.id)
              .then(({ data }) => {
                this.request = data;
                this.busy = false;
              });
    },
    updateQuestion(question) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/client/mymop/questions/" + question.id, {
          request_question: question
        });
    },
    submitResponse() {
      this.busySending = true;
      var confirmed = confirm("Your response will be sent to your practitioner. You will not be able to modify your response after submission. Continue?");
      if (confirmed) {
        this.$axios
                .post(process.env.VUE_APP_API_URL + "/client/mymop/requests/" + this.request.id + "/submit")
                .then(({data}) => {
                  this.busySending = false;
                  this.request = data.request;
                  this.$EventBus.$emit('alert', data);
                });
      }
    },
    nextQuestion() {
      this.started = true;
      if (this.currentQuestionIndex) {
        this.currentQuestionIndex++;
      } else {
        this.currentQuestionIndex = 1;
      }

      this.currentQuestion = this.request.questions[this.currentQuestionIndex-1];
    },
    prevQuestion() {
      this.currentQuestionIndex--;
      this.currentQuestion = this.request.questions[this.currentQuestionIndex-1];
    }
  },
  mounted() {
    this.fetchRequest();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatStatus(status) {
      if (status === "sent"){
        return "Awaiting Response";
      } else {
        return status;
      }
    }
  },
  components: {
    VueSlider,
  }
};
</script>

<style>
</style>
